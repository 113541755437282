<template>
    <div class="container">
        <div class="contact-info__header tc scroll-from-bottom">
            <h1>CONTACT US</h1>
            <h1>How can we help?</h1>
            <p>If you got any questions please do not hesitate to send us a message</p>
        </div>
        <section class="contact-info__form-wrapper ">
            <form class="contact-info__form-container scroll-from-left" v-on:submit="mySubmitFunction" >
                <div class="contact-info__form-card">
                    <div class="label">Name</div>
                    <input required class="form-control" v-model="form.name">
                </div>
                <div class="contact-info__form-card">
                    <div class="label">Email Address</div>
                    <input required class="form-control"  v-model="form.email"  type="email">
                </div>
                  <div class="contact-info__form-card">
                    <div class="label">Contact Number</div>
                    <input required class="form-control"  v-model="form.number"  type="number">
                </div>
                  <div class="contact-info__form-card">
                    <div class="label">Subject</div>
                    <input required class="form-control"  v-model="form.subject">
                </div>
                <div class="contact-info__form-card">
                    <div class="label">Message</div>
                    <textarea required class="form-control" rows="10"  v-model="form.message"></textarea>
                </div>
                <vue-recaptcha
                    sitekey="6LejPK0jAAAAAB-vqZrmhHGb-W8m2uR7zyaE1xhe"
                    @verify="verifyRecaptcha"
                    @expired="expiredRecaptcha"
                >
                </vue-recaptcha>
                 <!-- <div class="g-recaptcha" data-sitekey="6LejPK0jAAAAAB-vqZrmhHGb-W8m2uR7zyaE1xhe" data-callback="recaptchaCallback()"></div> -->
                <div >
                    <button class="contact-info__btn" :class="{'disable': btn.isLoading}">{{ btn.message }}</button>
                </div>
            </form>
            <div v-if="!isMobile" class="contact-info__wrapper scroll-from-right">
                <div class="contact-info__card">
                    <img src="@/assets/img/icon_phone_white.png">
                    <div>PHONE</div>
                    <div>+603 3101 4800</div>
                </div>
                 <div class="contact-info__card">
                    <img src="@/assets/img/icon_email_white.png">
                    <div>EMAIL</div>
                    <div>ask.sxdb@shuixingventures.com</div>
                </div>
                 <div class="contact-info__card">
                    <img src="@/assets/img/icon_location_white.png">
                    <div>ADDRESS</div>
                    <div>A-31-G, 
                        Jalan Bayu Laut 15B/KS09, 
                        Kota Bayu Emas, 
                        Pendamar, 41200 Klang, 
                        Selangor Darul Ehsan,
                        Malaysia</div>
                </div>
            </div>
        </section>
         <div v-if="isMobile" class="contact-info__wrapper">
                <div class="contact-info__card">
                    <img src="@/assets/img/icon_phone_white.png">
                    <div>PHONE</div>
                    <div>+603 3101 4800</div>
                </div>
                 <div class="contact-info__card">
                    <img src="@/assets/img/icon_email_white.png">
                    <div>EMAIL</div>
                    <div>ask.sxdb@shuixingventures.com</div>
                </div>
                 <div class="contact-info__card">
                    <img src="@/assets/img/icon_location_white.png">
                    <div>ADDRESS</div>
                    <div>A-31-G, 
                        Jalan Bayu Laut 15B/KS09, 
                        Kota Bayu Emas, 
                        Pendamar, 41200 Klang, 
                        Selangor Darul Ehsan,
                        Malaysia</div>
                </div>
            </div>
        <section class="contact-info__location-wrapper has-space scroll-from-bottom">
            <p class="contact-info__location-title">Location</p>
            <iframe class="contact-info__location-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.4149504492675!2d101.42685541517982!3d2.9822389978280808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cd073c8a1f9945%3A0x29cd8b5cc6f2ad7!2sShui%20Xing%20Ventures%20Sdn%20Bhd%20(HQ)!5e0!3m2!1sen!2smy!4v1672109390098!5m2!1sen!2smy" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';

import {mapGetters} from 'vuex'
   
export default {
    components: {
        VueRecaptcha
    },
    computed:{
		...mapGetters([              
			'isMobile',
		]),
	},
    data() {
        return {
            form: {
                name: null,
                email: null,
                number: null,
                subject: null,
                message: null
            },
            btn: {
                message: "SEND MESSAGE",
                isLoading: true
            },
            recaptchaValue: null,
        }
    },  
    mounted() {
      
         window.sr = ScrollReveal();
	    this.$nextTick(() => {
      		sr.reveal('.scroll-from-left', {
				origin: 'left' ,
				duration: 1000,
				easing: 'ease-out' ,
				distance: '500px',
				delay: 100,
				         
			});
			    sr.reveal('.scroll-from-right', {
                    origin: 'right' ,
                    duration: 1000,
                    easing: 'ease-out' ,
                    distance: '200px',
                    delay: 100     ,
					     
                });
                sr.reveal('.scroll-from-bottom', {    
                    duration: 1000,
                    distance: '50px',
                    delay: 100              
                });
                sr.reveal('.scroll-from-top', {    
                    origin: 'top' ,
                    duration: 1000,
                    distance: '200px',
                    delay: 100              
                });
				  sr.reveal('.scroll-from-opacity', {    
                       distance: '200px',
						opacity: 0.8,
						duration: 1000,
                });
				 sr.reveal('.scroll-from-scaleUp', {    
                       distance: '200px',
   				scale: 0.85,
                duration: 1000,
                });
      	    });  
    },
    methods: {
        
        mySubmitFunction(e)
            {  
                e.preventDefault();
                let formName = this.form.name;
                let formEmail = this.form.email;
                let formContactNumber = this.form.number;
                let formSubject = this.form.subject;
                let formMessage = this.form.message;
                // let formSell = document.getElementById('formSell').value;
                // let formLink = document.getElementById('formLink').value;
                
                let param = {
                    name: formName,
                    email: formEmail,
                    contactNumber: formContactNumber,
                    subject: formSubject,
                    message: formMessage,
                    recaptchaValue: this.recaptchaValue,
                    // sell: formSell,
                    // websiteLink: formLink,
                    title: 'SXDB'
                }
                this.btn.isLoading = true;
                this.btn.message = "Sending";
                let that = this;
                $.ajax({
                    url: 'https://api.vv-ehouse.com/api/email',
                
                    type: 'post',
                    contentType: 'application/json',
                    data: JSON.stringify(param),
                    processData: false,
                    success: function( data, textStatus, jQxhr ){
                        that.btn.isLoading = false; 
                        that.btn.message = "SEND MESSAGE";
                 
                        alert("Thank you for your inquiry!")
                    },
                    error: function( jqXhr, textStatus, errorThrown ){  
                        that.btn.isLoading = false;    
                        that.btn.message = "SEND MESSAGE";

                        alert(jqXhr.responseText)
                    },
                });
                return false
        },

        verifyRecaptcha(value) {
            this.recaptchaValue = value;
            this.btn.isLoading = false;
        },

        expiredRecaptcha() {
            this.btn.isLoading = true;
        }
    },
}
</script>

<style>
.contact-info__header{
    margin-top: 4.9375rem;
    margin-bottom: 4.5rem;
}
.contact-info__header h1{
    color: var(--color-blue);
    margin: 0;
    font-size: 3.125rem;
}
.contact-info__form-wrapper{
    display: flex;
    background-color: white;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 1rem;
    column-gap: 2rem;
    margin-bottom: 6.125rem;
    box-shadow: 5px 6px 13px 3px #c7c7c7;
}
.contact-info__wrapper{
    background-color: var(--color-blue);
    color: white;
    border-radius: 1rem;
    padding: 5.9375rem 1.875rem;
    height: 25rem;
}
.contact-info__form-container{
    flex: 1;
}
.contact-info__card{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
    width: 17rem;
    text-align: center;
    margin-bottom: 2rem;
}
.contact-info__form-card{
    margin-bottom: 1.3rem;
}
.label{
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: .5rem;
}
.contact-info__btn{
    background-color: transparent;
    border: 1px solid var(--color-theme);
    color: var(--color-theme);
    padding: 0.875rem 6.78125rem;
    cursor: pointer;
    transition: all .3s;
    margin-top: 1rem;
}
.contact-info__btn.disable{
    pointer-events: none;
    background-color: #cfcfcf;
    border-color: #cfcfcf;
    color: black;
}
.contact-info__btn:hover{
    background-color: var(--color-theme);
    color: white;
}

/* location */
.contact-info__location-wrapper{
    margin-bottom: 6rem;
}
.contact-info__location-map{
    border-radius: 1rem;
}
@media (max-width: 768px) {
    .contact-info__header{
        margin-top: 1.3rem;
        margin-bottom: 1.3rem;
        padding: 0 1rem;
    }
    .contact-info__header h1{
        font-size: 2rem;
    }
    .contact-info__form-wrapper{
        margin: 0 1rem 3rem;
        padding: .8rem;
    }
    .contact-info__btn{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .label{
        font-size: 1rem;
    }
    .contact-info__wrapper{
        margin: 0 1rem;
        height: auto;
        padding: 2rem;
    }
    .contact-info__card{
        width: 100%;
    }
    .contact-info__location-title{
        font-weight: bold;
        font-size: 1.5rem;
    }
}
</style>